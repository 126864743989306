import 'firebase/firestore';
import 'firebase/database';

import { fire, perf } from './firebase';
import { APICache } from './cache';

export interface Metadata {
  id: string;
  info: {
    id: string;
    name: string;
    category: string;
  };
}

export interface ScheduleMetadata {
  id: string;
  name: string;
  category: string;
}

export interface Schedule {
  days: {
    [day: string]: ScheduleDay;
  };
}
export interface ScheduleDay {
  [period: string]: SchedulePeriod;
}
export type SchedulePeriod = ScheduleClass[];
export interface ScheduleClass {
  classes: string[];
  id: string;
  room: string;
  subject: string;
  subjectShort: string;
  teachers: string[];
  mutation?: ScheduleClassMutation;
}

export interface ScheduleClassMutation {
  classes?: string[];
  room?: string;
  subject?: string;
  subjectShort?: string;
  teachers?: string[];
  type: string;
}

interface RawSchedule {
  p: {
    [day: string]: RawScheduleDay;
  };
}

export interface RawScheduleDay {
  [period: string]: string;
}
export type RawSchedulePeriod = RawScheduleClass[];
export interface RawScheduleClass {
  c: string[];
  id: string;
  r: string;
  s: string;
  ss: string;
  t: string[];
  m?: {
    c: string[];
    r: string;
    s: string;
    ss: string;
    t: string[];
    tp: string;
  };
}

export class RTDBScheduleService {
  private cache: APICache;

  constructor() {
    this.cache = new APICache('rtdb_schedule_service', 30000);
  }

  async getMetadata(): Promise<Metadata[]> {
    const trace = perf.trace('get_metadata');
    trace.start();
    trace.putAttribute('cached', 'true');
    const metadata = await this.cache.get(
      'schedule_metadata',
      async () => {
        trace.putAttribute('cached', 'false');
        var documents = await fire.database().ref('metadata').once('value');

        var map: Metadata[] = [];

        for (const i of Object.keys(documents.val())) {
          let info = documents.val()[i];

          info.id = info.id
            .replace(/^ST_/, '')
            .replace(/^RM_/, '')
            .replace(/^CL_/, '')
            .replace(/^TR_/, '');

          info.category = info.category
            .replace(/^ST_/, '')
            .replace(/^RM_/, '')
            .replace(/^CL_/, '')
            .replace(/^TR_/, '');

          map.push({
            id: i,
            info: info,
          });
        }

        return map;
      },
      1800000
    );
    trace.putMetric('metadata_length', metadata.length);
    trace.stop();
    return metadata;
  }

  async getSchedule(id: string, week: string): Promise<Schedule> {
    const trace = perf.trace('get_schedule');
    trace.start();
    trace.putAttribute('cached', 'true');
    const schedule = await this.cache.get(
      `schedule/${id}/${week}`,
      async () => {
        trace.putAttribute('cached', 'false');
        const doc = await fire
          .database()
          .ref(`schedules/${id}/weeks/${week}`)
          .once('value');

        const rawSchedule = doc.val() as RawSchedule;

        let schedule: Schedule = { days: {} };

        if (rawSchedule && rawSchedule.p) {
          Object.keys(rawSchedule.p).forEach((dayIndex) => {
            const rawDay = rawSchedule.p[dayIndex];
            let day: ScheduleDay = {};

            Object.keys(rawDay).forEach((periodIndex) => {
              const rawPeriodString = rawDay[periodIndex];
              const rawPeriod: RawScheduleClass[] =
                JSON.parse(rawPeriodString) || [];
              const period = rawPeriod.map<ScheduleClass>((rawClass) => {
                const mutation: ScheduleClassMutation | null = rawClass.m
                  ? {
                      classes: rawClass.m.c,
                      room: rawClass.m.r,
                      subject: rawClass.m.s,
                      subjectShort: rawClass.m.ss,
                      teachers: rawClass.m.t,
                      type: rawClass.m.tp,
                    }
                  : null;

                if (mutation) {
                  if (!mutation.classes) delete mutation.classes;
                  if (!mutation.room) delete mutation.room;
                  if (!mutation.subject) delete mutation.subject;
                  if (!mutation.subjectShort) delete mutation.subjectShort;
                  if (!mutation.teachers) delete mutation.teachers;
                }

                return {
                  classes: rawClass.c,
                  id: rawClass.id,
                  room: rawClass.r,
                  subject: rawClass.s,
                  subjectShort: rawClass.ss,
                  teachers: rawClass.t,
                  mutation: mutation,
                };
              });

              day[periodIndex] = period;
            });

            schedule.days[dayIndex] = day;
          });
        } else {
          throw Error('Schedule not found.');
        }
        return schedule;
      },
      300000
    );
    trace.stop();
    return schedule;
  }

  async getScheduleMeta(id: string): Promise<ScheduleMetadata> {
    const trace = perf.trace('get_schedule_metadata');
    trace.start();
    trace.putAttribute('cached', 'true');
    const scheduleMetadata = await this.cache.get(
      `schedule_metadata/${id}`,
      async () => {
        trace.putAttribute('cached', 'false');
        var doc = await fire.database().ref(`metadata/${id}`).once('value');
        return doc.val() as ScheduleMetadata;
      },
      1800000
    );
    trace.stop();
    return scheduleMetadata;
  }
}
