import React, { useState, useEffect, useMemo } from 'react';
import { RTDBScheduleService } from '../services/schedules';

import { Search } from 'semantic-ui-react';

import './selector.scss';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { SettingsService } from '../services/settings';
import { SearchService, SearchResult } from '../services/search';

interface SelectorProps extends LocalizeContextProps {
  update: (id: string, name: string) => void;
  settingsService?: SettingsService;
}

function Selector(props: SelectorProps) {
  const service = useMemo(
    () => new SearchService(new RTDBScheduleService(), props.translate),
    [props.translate]
  );
  const [search, setSearch] = useState('');
  const [results, setResults] = useState<SearchResult>(null);

  async function selectSchedule(id: string, name: string) {
    if (props.settingsService) {
      await props.settingsService.selectSchedule(id);
    }

    if (props.update) {
      props.update(id, name);
    }
  }

  useEffect(() => {
    const input = document.querySelector('input#search') as HTMLInputElement;
    if (input) {
      input.focus();
    }
  }, []);

  useEffect(() => {
    service.search(search).then((r) => setResults(r));
  }, [service, search]);

  return (
    <div style={{ width: '100%' }}>
      <Search
        style={{ margin: '0 auto' }}
        autoFocus
        selectFirstResult
        fluid
        category
        loading={results == null}
        results={results || {}}
        value={search}
        onSearchChange={(e, data) => setSearch(data.value)}
        onResultSelect={(e, data) => {
          setSearch('');
          selectSchedule(data.result.id, data.result.title);
        }}
      />
    </div>
  );
}

export default withLocalize(Selector);
