import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Container, Icon } from 'semantic-ui-react';
import { SearchModal } from './search';
import { Translate } from 'react-localize-redux';
import { preferences } from '../services/preferences';
import Mousetrap from 'mousetrap';

interface NavbarProps {
  small?: boolean;
  showSearch?: boolean;
  showSettings?: boolean;
  invisible?: boolean;
}

export default function Navbar(props: NavbarProps) {
  const [searchOpen, setSearchOpen] = useState(false);

  useEffect(() => {
    Mousetrap.bind('ctrl+f', e => {
      e.preventDefault();
      setSearchOpen(true);
    });
    return () => {
      Mousetrap.unbind('ctrl+f');
    };
  }, []);

  return (
    <>
      <Menu
        style={{ display: props.invisible ? 'none' : '' }}
        pointing
        secondary
        size='huge'
        inverted={preferences.isDarkModeEnabled}>
        <Container text={props.small}>
          <Menu.Item header style={{ padding: 11 }} as={Link} to='/'>
            <img src='/logo.svg' alt='' height='24' />
            &nbsp;&nbsp; <Translate id='general.name' />
          </Menu.Item>

          <Menu.Menu position='right'>
            {props.showSearch ? (
              <Menu.Item onClick={() => setSearchOpen(true)}>
                <Icon name='search' /> <Translate id='search.search' />
              </Menu.Item>
            ) : null}

            {props.showSettings ? (
              <Menu.Item as={Link} to='/settings'>
                <Icon name='settings' />
              </Menu.Item>
            ) : null}
          </Menu.Menu>

          {searchOpen ? (
            <SearchModal close={() => setSearchOpen(false)} />
          ) : null}
        </Container>
      </Menu>
    </>
  );
}
