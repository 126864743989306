import { preferences } from './preferences';

export class APICache {
  private prefix: string;
  private expiration: number;

  constructor(prefix: string, expiration: number) {
    this.prefix = prefix;
    this.expiration = expiration;
  }

  invalidate(id: string): void {
    try {
      window.localStorage.removeItem(`__cache__/${this.prefix}/${id}`);
    } catch (e) {}
  }

  async get<T>(
    id: string,
    fallback: () => Promise<T>,
    expiration?: number
  ): Promise<T> {
    expiration = expiration ? expiration : this.expiration;

    if (!preferences.cacheDisabled) {
      try {
        var cachedString = window.localStorage.getItem(
          `__cache__/${this.prefix}/${id}`
        );

        if (cachedString) {
          var cached = JSON.parse(cachedString);
        }
      } catch (e) {
        cached = undefined;
      }

      if (
        cached !== undefined &&
        cached !== null &&
        cached.timestamp + expiration > new Date().getTime()
      ) {
        return cached.data;
      }
    }

    try {
      var data = await fallback();
    } catch (e) {
      throw e;
    }

    localStorage.setItem(
      `__cache__/${this.prefix}/${id}`,
      JSON.stringify({
        timestamp: new Date().getTime(),
        data,
      })
    );

    return data;
  }

  static invalidateAll() {
    const toRemove: string[] = [];
    for (let i = 0; i < window.localStorage.length; i++) {
      const key = window.localStorage.key(i);
      if (key.startsWith('__cache__/')) toRemove.push(key);
    }
    toRemove.forEach(key => window.localStorage.removeItem(key));
  }
}
