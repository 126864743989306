import * as Sentry from "@sentry/react";
import { isDevelopment } from "./services";

Sentry.init({
  environment: isDevelopment ? "development" : "production",
  // @ts-ignore
  release: "ocrooster2web@" + process.env.REACT_APP_COMMIT,
  dsn:
    "https://0e1ba12b01cd445f80bdec55df45e093@o148337.ingest.sentry.io/1826992",
});

export function reportError(err: Error) {
  Sentry.captureException(err);
}
