import 'firebase/auth';

import { fire, perf } from './firebase';
import firebase from 'firebase/app';
import { reportError } from './sentry';

type User = firebase.User;
type ActionCodeInfo = firebase.auth.ActionCodeInfo;
export type { ActionCodeInfo, User };

export type Role =
  | ''
  | 'student'
  | 'teacher'
  | 'coordinator'
  | 'scheduler'
  | 'admin';

export class AuthService {
  public user: firebase.User;
  public role: Role;
  public validated: boolean;
  public ready: boolean;
  public signout_intent: boolean;

  constructor() {
    fire.auth().setPersistence('local');

    this.user = fire.auth().currentUser;
    this.role = '';
    this.validated = false;
    this.ready = false;
    this.signout_intent = false;

    this.onChange((user) => {
      this.user = user;
      this.ready = true;
    });
  }

  onChange(
    callback: (user: firebase.User, status?: Role, validated?: boolean) => void
  ) {
    fire.auth().onIdTokenChanged(async (user) => {
      if (user === null) {
        return callback(user, '', false);
      }

      var result = await user.getIdTokenResult();

      this.role = result.claims.role;
      this.validated = result.claims.validated;
      return callback(user, result.claims.role, result.claims.validated);
    });
    return;
  }

  async signIn(
    email: string,
    password: string
  ): Promise<{
    user: firebase.auth.UserCredential;
    status: 'credentials_incorrect' | 'signin_failed';
  }> {
    const trace = perf.trace('signin_emailpassword');
    trace.start();
    this.signout_intent = false;

    try {
      var user = await fire.auth().signInWithEmailAndPassword(email, password);
    } catch (error) {
      trace.putAttribute('status', 'credentials_incorrect');
      trace.stop();
      reportError(error);
      return { user, status: 'credentials_incorrect' };
    }

    if (user === null) {
      trace.putAttribute('status', 'signin_failed');
      trace.stop();
      return { user, status: 'signin_failed' };
    }

    trace.putAttribute('status', 'success');
    trace.stop();
    return { user, status: null };
  }

  async signInWithMicrosoft(): Promise<{
    user: firebase.auth.UserCredential;
    status: 'credentials_incorrect' | 'signin_failed';
  }> {
    const trace = perf.trace('signin_microsoft');
    trace.start();
    this.signout_intent = false;

    const provider = new firebase.auth.OAuthProvider('microsoft.com');
    provider.setCustomParameters({
      domain_hint: 'olympuscollege.nl',
    });

    try {
      var user = await fire.auth().signInWithPopup(provider);
    } catch (error) {
      trace.putAttribute('status', 'signin_failed');
      trace.stop();
      reportError(error);
      return { user, status: 'signin_failed' };
    }

    if (user === null) {
      trace.putAttribute('status', 'signin_failed');
      trace.stop();
      return { user, status: 'signin_failed' };
    }

    trace.putAttribute('status', 'success');
    trace.stop();
    return { user, status: null };
  }

  async signOut() {
    this.signout_intent = true;
    return fire.auth().signOut();
  }

  async forceRefresh() {
    await this.user.getIdToken(true);
  }

  async sendPasswordResetEmail(email: string) {
    try {
      await fire.auth().sendPasswordResetEmail(email);
    } catch (error) {
      reportError(error);
      return false;
    }

    return true;
  }

  async getParams() {
    const urlParams = new URLSearchParams(window.location.search);

    return {
      mode: urlParams.get('mode'),
      actionCode: urlParams.get('oobCode'),
      continueUrl: urlParams.get('continueUrl'),
      actionInfo: await fire.auth().checkActionCode(urlParams.get('oobCode')),
    };
  }

  async verifyEmail(code: string) {
    try {
      await fire.auth().applyActionCode(code);
    } catch (error) {
      reportError(error);
      return false;
    }

    return true;
  }

  async passwordResetChange(code: string, password: string) {
    try {
      await fire.auth().confirmPasswordReset(code, password);
    } catch (error) {
      reportError(error);
      return false;
    }

    return true;
  }
}
