import React, { useState, useEffect } from 'react';
import DefaultNavbar from './navbar';
import {
  Container,
  Segment,
  Placeholder,
  Header,
  Form,
  Button,
  Grid,
  Divider,
  Message,
  Progress,
} from 'semantic-ui-react';
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from 'react-localize-redux';
import { preferences } from '../services/preferences';
import { AuthService, Role } from '../services/authentication';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { Footer } from './footer';
import { useAuth } from './context';

interface LoginProps extends LocalizeContextProps {
  dashboard: boolean;
  enableEmailSignin?: boolean;
}

export const Login = withLocalize((props: LoginProps) => {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInStatus, setSignInStatus] = useState<
    'credentials_incorrect' | 'signin_failed'
  >(null);
  const [resetEmail, setResetEmail] = useState('');
  const [resetStatus, setResetStatus] = useState<'success' | 'failed'>(null);

  async function signIn() {
    setLoading(true);

    var info = await auth.signIn(email, password);

    setLoading(false);
    setSignInStatus(info.status);
  }

  async function signInWithMicrosoft() {
    setLoading(true);

    var info = await auth.signInWithMicrosoft();

    setLoading(false);
    setSignInStatus(info.status);
  }

  async function resetPassword() {
    setLoading(true);

    var success = await auth.sendPasswordResetEmail(resetEmail);
    setLoading(false);
    setResetStatus(success ? 'success' : 'failed');
  }

  return (
    <>
      <DefaultNavbar small />
      <Container text>
        <Segment inverted={preferences.isDarkModeEnabled}>
          <Grid columns={props.enableEmailSignin ? 2 : 1} relaxed='very'>
            <Grid.Column>
              <Header size='huge' inverted={preferences.isDarkModeEnabled}>
                <Translate id='login.login.title' />
              </Header>
              <p>
                <Translate id='login.login.description' />
              </p>
              <Button
                onClick={() => (!loading ? signInWithMicrosoft() : null)}
                type='submit'
                fluid
                color='pink'
                loading={loading}
                disabled={loading}
                inverted={preferences.isDarkModeEnabled}>
                <Translate id='login.login.button_microsoft' />
              </Button>
              {props.enableEmailSignin ? (
                <>
                  <Divider horizontal inverted={preferences.isDarkModeEnabled}>
                    <Translate id='general.or' />
                  </Divider>
                  <Form
                    onSubmit={() => signIn()}
                    loading={loading}
                    inverted={preferences.isDarkModeEnabled}>
                    <Form.Field>
                      <label>
                        <Translate id='login.email' />
                      </label>
                      <input
                        placeholder={props.translate('login.email') as string}
                        type='email'
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                      />
                    </Form.Field>
                    <Form.Field>
                      <label>
                        <Translate id='login.password' />
                      </label>
                      <input
                        placeholder={
                          props.translate('login.password') as string
                        }
                        type='password'
                        onChange={e => setPassword(e.target.value)}
                        value={password}
                      />
                    </Form.Field>
                    <Button
                      type='submit'
                      fluid
                      inverted={preferences.isDarkModeEnabled}>
                      <Translate id='login.login.button' />
                    </Button>
                  </Form>
                </>
              ) : null}
              {signInStatus === 'credentials_incorrect' ? (
                <Message
                  error
                  header={props.translate(
                    'login.login.status.credentials_incorrect.title'
                  )}
                  content={props.translate(
                    'login.login.status.credentials_incorrect.description'
                  )}
                />
              ) : null}
              {signInStatus === 'signin_failed' ? (
                <Message
                  error
                  header={props.translate(
                    'login.login.status.signin_failed.title'
                  )}
                  content={props.translate(
                    'login.login.status.signin_failed.description'
                  )}
                />
              ) : null}
            </Grid.Column>
            {props.enableEmailSignin ? (
              <>
                <Grid.Column>
                  <Header size='huge' inverted={preferences.isDarkModeEnabled}>
                    <Translate id='login.resetPassword.title' />
                  </Header>
                  <p>
                    <Translate id='login.resetPassword.description' />
                  </p>
                  <Form
                    onSubmit={() => resetPassword()}
                    loading={loading}
                    inverted={preferences.isDarkModeEnabled}>
                    <Form.Field>
                      <label>
                        <Translate id='login.email' />
                      </label>
                      <input
                        placeholder={props.translate('login.email') as string}
                        type='email'
                        onChange={e => setResetEmail(e.target.value)}
                        value={resetEmail}
                      />
                    </Form.Field>
                    <Button
                      type='submit'
                      fluid
                      inverted={preferences.isDarkModeEnabled}>
                      <Translate id='login.resetPassword.button' />
                    </Button>
                  </Form>
                  {resetStatus === 'failed' ? (
                    <Message
                      error
                      header={props.translate(
                        'login.resetPassword.status.failed.title'
                      )}
                      content={props.translate(
                        'login.resetPassword.status.failed.description'
                      )}
                    />
                  ) : resetStatus === 'success' ? (
                    <Message
                      success
                      header={props.translate(
                        'login.resetPassword.status.success.title'
                      )}
                      content={props.translate(
                        'login.resetPassword.status.success.description'
                      )}
                    />
                  ) : null}
                </Grid.Column>
              </>
            ) : null}
          </Grid>
          {props.enableEmailSignin ? (
            <>
              <Divider vertical inverted={preferences.isDarkModeEnabled}>
                <Translate id='general.or' />
              </Divider>
            </>
          ) : null}
        </Segment>
      </Container>
      <Footer small />
    </>
  );
});

interface LoginPlaceholderProps {
  dashboard: boolean;
}

export function LoginPlaceholder(props: LoginPlaceholderProps) {
  return (
    <>
      <DefaultNavbar small />
      <Container text>
        <Segment inverted={preferences.isDarkModeEnabled}>
          <Placeholder inverted={preferences.isDarkModeEnabled}>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Segment>
        <Segment inverted={preferences.isDarkModeEnabled}>
          <Placeholder inverted={preferences.isDarkModeEnabled}>
            <Placeholder.Header>
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        </Segment>
      </Container>
    </>
  );
}

export function SignOut(props: { auth: AuthService }) {
  const [signedOut, setSignedOut] = useState(false);

  useEffect(() => {
    props.auth.signOut().then(() => setSignedOut(true));
  }, [props.auth]);

  if (signedOut) return <Redirect to='/' push />;

  return <></>;
}

export function CreatingAccount(props: {}) {
  const auth = useAuth();
  const [lastTry, setLastTry] = useState(new Date());
  useEffect(() => {
    (async () => {
      await new Promise(resolve => setTimeout(resolve, 500));
      await auth.forceRefresh();
      setLastTry(new Date());
    })();
  }, [auth, lastTry]);

  return (
    <>
      <DefaultNavbar small />
      <Container text>
        <Segment inverted={preferences.isDarkModeEnabled}>
          <Header size='huge' inverted={preferences.isDarkModeEnabled}>
            <Translate id='login.creating_account.title' />
          </Header>
          <p>
            <Translate id='login.creating_account.text' />
          </p>
          <Progress
            active
            percent={100}
            inverted={preferences.isDarkModeEnabled}></Progress>
          <Button
            as={Link}
            to={'/signout'}
            inverted={preferences.isDarkModeEnabled}>
            <Translate id='login.creating_account.signout' />
          </Button>
        </Segment>
      </Container>
      <Footer small />
    </>
  );
}

interface AccessProhibitedProps {
  dashboard: boolean;
  allowedRoles: Role[];
}

export function AccessProhibited(props: AccessProhibitedProps) {
  return (
    <>
      <DefaultNavbar small />
      <Container text>
        <Segment inverted={preferences.isDarkModeEnabled}>
          <Header size='huge' inverted={preferences.isDarkModeEnabled}>
            <Translate id='login.access_prohibited.title' />
          </Header>
          <p>
            <Translate id='login.access_prohibited.text' />
          </p>
          <Button
            as={Link}
            to={'/signout'}
            inverted={preferences.isDarkModeEnabled}>
            <Translate id='login.access_prohibited.signout' />
          </Button>
        </Segment>
      </Container>
      <Footer small />
    </>
  );
}
