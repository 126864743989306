import 'semantic-ui-css/semantic.min.css';
import './services/sentry';

import React from 'react';
import ReactDOM from 'react-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import * as swLoader from './serviceWorkerLoader';
import Localizations from './localizations';
import { fire } from './services/firebase';

ReactDOM.render(
  <LocalizeProvider>
    <Router>
      <Localizations>
        <App />
      </Localizations>
    </Router>
  </LocalizeProvider>,
  document.getElementById('root')
);

swLoader.register(fire);
