import React, { useState } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import Selector from './selector';
import { Translate } from 'react-localize-redux';
import { preferences } from '../services/preferences';
import { Redirect } from 'react-router';

interface SearchModalProps {
  close: () => void;
}

export function SearchModal(props: SearchModalProps) {
  const [redirect, setRedirect] = useState<string>(null);

  if (redirect)
    return (
      <>
        <Redirect to={redirect} push />
        {props.close()}
      </>
    );

  return (
    <Modal
      size='tiny'
      open={true}
      onClose={() => props.close()}
      basic={preferences.isDarkModeEnabled}
      centered={false}>
      <Modal.Header>
        <Translate id='search.title' />
      </Modal.Header>
      <Modal.Content>
        <p>
          <Translate id='search.description' />
        </p>
        <Selector
          update={id => {
            setRedirect(`/schedule/${id}`);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          inverted={preferences.isDarkModeEnabled}
          onClick={() => props.close()}>
          <Translate id='general.cancel' />
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
