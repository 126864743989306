import { createContext, useContext } from 'react';
import { Config } from '../services/config';
import { AuthService } from '../services/authentication';

export const ConfigContext = createContext<Config>(null);

export const useConfig = () => {
  return useContext(ConfigContext);
};

export const AuthContext = createContext<AuthService>(null);

export const useAuth = () => {
  return useContext(AuthContext);
};
