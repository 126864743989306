import React, { useEffect, useReducer, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Config, ConfigService } from './services/config';

import './app.scss';
import { preferences } from './services/preferences';
import { AuthService, User } from './services/authentication';
import { SignOut } from './components/login';
import { SettingsService } from './services/settings';
import { AuthContext, ConfigContext } from './components/context';
import { Translate, withLocalize } from 'react-localize-redux';

const AuthRoute = React.lazy(() => import('./routes/auth'));
const LoginGate = React.lazy(() => import('./routes/login'));
const RouteHome = React.lazy(() => import('./routes/home'));
const RoutePrivacy = React.lazy(() => import('./routes/privacy'));
const RouteHelp = React.lazy(() => import('./routes/help'));
const RouteSchedule = React.lazy(() => import('./routes/schedule'));
const RouteSettings = React.lazy(() => import('./routes/settings'));
const SuperviewRoute = React.lazy(() => import('./routes/superview'));
const PlannerRoute = React.lazy(() => import('./routes/planner'));
const DashboardApp = React.lazy(() => import('./routes/dashboard/app'));
const RouteUnsubscribeEmailNotifications = React.lazy(
  () => import('./routes/unsubscribeEmailNotifications')
);

function App() {
  const [auth] = useState<AuthService>(new AuthService());
  const [settingsService] = useState<SettingsService>(
    new SettingsService(auth)
  );
  const [user, setUser] = useState<User | null>(null);
  const forceUpdate = useReducer((x) => x + 1, 0)[1];

  useEffect(() => {
    auth.onChange((u) => {
      setUser(u);
      forceUpdate();
    });
  }, [auth, forceUpdate]);

  const [config, setConfig] = useState<Config | null>(null);

  useEffect(() => {
    if (user) {
      const configService = new ConfigService();
      configService.getConfig().then((config) => {
        setConfig(config);
      });
    }
  }, [user]);

  return (
    <React.Suspense
      fallback={
        <div>
          <div
            className={
              'ui secondary pointing huge menu' +
              (preferences.isDarkModeEnabled ? ' inverted' : '')
            }>
            <div className='ui container text'>
              <div
                className={
                  'header item' +
                  (preferences.isDarkModeEnabled ? ' inverted' : '')
                }
                style={{ padding: 11 }}>
                <img src='/logo.svg' alt='' height='24' />
                &nbsp;&nbsp; <Translate id='general.name' />
              </div>
            </div>
          </div>
          <div className='ui container text'>
            <div
              className={
                'ui segment' +
                (preferences.isDarkModeEnabled ? ' inverted' : '')
              }>
              <div
                className={
                  'ui placeholder' +
                  (preferences.isDarkModeEnabled ? ' inverted' : '')
                }>
                <div className='header'>
                  <div className='line' />
                </div>
                <div className='paragraph'>
                  <div className='line' />
                  <div className='line' />
                  <div className='line' />
                </div>
              </div>
            </div>
            <div
              className={
                'ui segment' +
                (preferences.isDarkModeEnabled ? ' inverted' : '')
              }>
              <div
                className={
                  'ui placeholder' +
                  (preferences.isDarkModeEnabled ? ' inverted' : '')
                }>
                <div className='header'>
                  <div className='line' />
                </div>
                <div className='header'>
                  <div className='line' />
                  <div className='line' />
                  <div className='line' />
                  <div className='line' />
                </div>
              </div>
            </div>
          </div>
        </div>
      }>
      <ConfigContext.Provider value={config}>
        <AuthContext.Provider value={auth}>
          <Switch>
            <Route path='/terms' render={(r) => <Terms />} />
            <Route path='/dashboard' render={(r) => <DashboardApp />} />
            <Route path='/privacy' render={(r) => <RoutePrivacy />} />
            <Route path='/help' render={(r) => <RouteHelp />} />
            <Route
              path='/superview'
              render={(r) => (
                <LoginGate
                  allowedRoles={[
                    'student',
                    'teacher',
                    'coordinator',
                    'scheduler',
                    'admin',
                  ]}>
                  <SuperviewRoute />
                </LoginGate>
              )}
            />
            <Route
              path='/planner'
              render={(r) => (
                <LoginGate
                  allowedRoles={[
                    'student',
                    'teacher',
                    'coordinator',
                    'scheduler',
                    'admin',
                  ]}>
                  <PlannerRoute />
                </LoginGate>
              )}
            />

            <Route
              path='/schedule/:scheduleID'
              exact
              render={(r) => (
                <LoginGate
                  allowedRoles={[
                    'student',
                    'teacher',
                    'coordinator',
                    'scheduler',
                    'admin',
                  ]}>
                  <RouteSchedule
                    scheduleID={r.match.params.scheduleID}
                    settingsService={settingsService}
                  />
                </LoginGate>
              )}
            />
            <Route
              path='/settings'
              exact
              render={(r) => (
                <LoginGate
                  allowedRoles={[
                    'student',
                    'teacher',
                    'coordinator',
                    'scheduler',
                    'admin',
                  ]}>
                  <RouteSettings settingsService={settingsService} />
                </LoginGate>
              )}
            />
            <Route
              path='/unsubscribe-email-notifications'
              exact
              render={(r) => (
                <LoginGate
                  allowedRoles={[
                    'student',
                    'teacher',
                    'coordinator',
                    'scheduler',
                    'admin',
                  ]}>
                  <RouteUnsubscribeEmailNotifications
                    settingsService={settingsService}
                  />
                </LoginGate>
              )}
            />
            <Route
              path='/auth'
              exact
              render={(r) => <AuthRoute auth={auth} />}
            />
            <Route
              path='/signout'
              exact
              render={(r) => {
                return <SignOut auth={auth} />;
              }}
            />
            <Route
              path='/'
              exact
              render={(r) => (
                <LoginGate
                  allowedRoles={[
                    'student',
                    'teacher',
                    'coordinator',
                    'scheduler',
                    'admin',
                  ]}>
                  <RouteHome settingsService={settingsService} />
                </LoginGate>
              )}
            />
            <Route render={(r) => <Redirect to='/' />} />
          </Switch>
        </AuthContext.Provider>
      </ConfigContext.Provider>
    </React.Suspense>
  );
}

const Terms = withLocalize(({ activeLanguage }) => {
  useEffect(() => {
    if (activeLanguage) {
      window.location.href =
        activeLanguage.code === 'nl'
          ? 'https://antipy.com/nl/terms'
          : 'https://antipy.com/terms';
    }
  }, [activeLanguage]);
  return <></>;
});

export default App;
