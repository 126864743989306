export const isDevelopment =
  // @ts-ignore
  (process.env.NODE_ENV && process.env.NODE_ENV === 'development') ||
  // @ts-ignore
  process.env.REACT_APP_GCLOUD_PROJECT === 'oc-rooster-staging';

if (isDevelopment) {
  console.log(
    '-------------------------\n\nRUNNING IN DEVELOPMENT MODE!\n\n-------------------------'
  );
}
