import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/performance';
import 'firebase/analytics';

import { isDevelopment } from './services';

export const fire = isDevelopment
  ? firebase.initializeApp({
      apiKey: 'AIzaSyC605A7T_JfQluW3wcApQKGU6hH7uv2wyE',
      authDomain: 'staging.v2.ocrooster.ga',
      databaseURL: 'https://oc-rooster-staging.firebaseio.com',
      projectId: 'oc-rooster-staging',
      storageBucket: 'oc-rooster-staging.appspot.com',
      messagingSenderId: '860136757269',
      appId: '1:860136757269:web:66026cd476ac939f',
      measurementId: 'G-GWSWV6ZX5S',
    })
  : firebase.initializeApp({
      apiKey: 'AIzaSyBK9e5Cqat6n22KCBfjKcCsvKfAxbbwAaw',
      authDomain: 'ocrooster.ga',
      databaseURL: 'https://oc-rooster.firebaseio.com',
      projectId: 'oc-rooster',
      storageBucket: 'oc-rooster.appspot.com',
      messagingSenderId: '319252402354',
      appId: '1:319252402354:web:a427d3595cb41a6e',
      measurementId: 'G-34M57NVHKK',
    });

export const vapidKey = isDevelopment
  ? 'BLaLI2SCNE6gATO6pIftQaPj9J41NDmktq1gN-O0agp9PLGWs3szEIqYFnqM5mPBNC9uxYzwdpqJh2bcPVzVJpY'
  : 'BKf_CS5wsVnCJg95JuID3Ap-u5r8zvKTEKXjz764DzJ_1YoUG0V_5TKX9T2pKMX81G1uylByc_gu9hcA0Cg0x5M';

export const perf = firebase.performance();
export const analytics = firebase.analytics();
