import firebase from 'firebase/app';
import 'firebase/messaging';

export const register = (fire: firebase.app.App) => {
  if (navigator.serviceWorker) {
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => {
        if (firebase.messaging.isSupported()) {
          fire.messaging().onMessage((payload) => {
            console.log('Message received. ', payload);
          });
        }
      })
      .catch((err) => console.log('Could  not install service worker: ' + err));
  }
};
