import { fire } from './firebase';
import { AuthService } from './authentication';
import firebase from 'firebase/app';

export interface Settings {
  pushTokens: string[];
  emailNotifications: boolean;
  defaultSchedule: string;
}

export class SettingsService {
  private auth: AuthService;

  constructor(auth: AuthService) {
    this.auth = auth;
  }

  async selectSchedule(id: string) {
    await this.updateSettings({
      defaultSchedule: id,
    });
  }

  async unselectSchedule() {
    await this.updateSettings({
      defaultSchedule: null,
    });
  }

  async enablePushNotifications(token: string) {
    await fire
      .firestore()
      .collection('settings')
      .doc(this.auth.user.uid)
      .update({
        pushTokens: firebase.firestore.FieldValue.arrayUnion(token),
      });
  }

  async disablePushNotificationsForDevice(token: string) {
    await fire
      .firestore()
      .collection('settings')
      .doc(this.auth.user.uid)
      .update({
        pushTokens: firebase.firestore.FieldValue.arrayRemove(token),
      });
  }

  async enableEmailNotifications() {
    await this.updateSettings({
      emailNotifications: true,
    });
  }

  async disableEmailNotifications() {
    await this.updateSettings({
      emailNotifications: false,
    });
  }

  async disableAllNotifications() {
    await fire
      .firestore()
      .collection('settings')
      .doc(this.auth.user.uid)
      .update({
        pushTokens: [],
        emailNotifications: false,
      });
  }

  private async setSettings(settings: Settings): Promise<void> {
    await fire
      .firestore()
      .collection('settings')
      .doc(this.auth.user.uid)
      .set(settings);
  }

  private async updateSettings(settings: Partial<Settings>): Promise<void> {
    await fire
      .firestore()
      .collection('settings')
      .doc(this.auth.user.uid)
      .update(settings);
  }

  getSettings(callback: (settings: Settings) => void): void {
    fire
      .firestore()
      .collection('settings')
      .doc(this.auth.user.uid)
      .onSnapshot((document) => {
        var settings: Settings;

        settings = document.data() as any;

        if (settings === undefined) {
          settings = {
            pushTokens: [],
            emailNotifications: false,
            defaultSchedule: null,
          };
          this.setSettings(settings).then();
        } else if (settings.pushTokens === undefined) {
          this.updateSettings({ pushTokens: [] });
        } else {
          callback(settings);
        }
      });
  }
}
