import React from 'react';
import {
  Segment,
  Container,
  Grid,
  Header,
  List,
  Icon,
  Divider,
  Label,
} from 'semantic-ui-react';
import { preferences } from '../services/preferences';
import { Link } from 'react-router-dom';
import { useAuth, useConfig } from './context';
import {
  Translate,
  withLocalize,
  LocalizeContextProps,
} from 'react-localize-redux';

interface FooterProps extends LocalizeContextProps {
  small?: boolean;
}

export const Footer = withLocalize(({ small }: FooterProps) => {
  const auth = useAuth();
  const config = useConfig();
  const canSeeDashboard =
    auth !== undefined && (auth.role === 'admin' || auth.role === 'scheduler');

  return (
    <Segment vertical className='footer'>
      <Divider />
      <Container text={small}>
        <Grid stackable divided inverted={preferences.isDarkModeEnabled}>
          <Grid.Column
            width={canSeeDashboard ? '4' : '5'}
            className='no vertical padding'>
            <Header inverted={preferences.isDarkModeEnabled} size='small'>
              <Translate id='footer.main.title' />
            </Header>
            <List inverted={preferences.isDarkModeEnabled} link size='medium'>
              <List.Item as={Link} to='/'>
                <Translate id='footer.main.schedule' />
              </List.Item>
              <List.Item as={Link} to='/planner'>
                <Translate id='footer.main.planner' />
              </List.Item>
              <List.Item as={Link} to='/superview'>
                <Translate id='footer.main.superview' />
              </List.Item>
            </List>
          </Grid.Column>
          {canSeeDashboard ? (
            <Grid.Column width='4' className='no vertical padding'>
              <Header inverted={preferences.isDarkModeEnabled} size='small'>
                <Translate id='footer.dashboard.title' />
              </Header>
              <List inverted={preferences.isDarkModeEnabled} link size='medium'>
                <List.Item as={Link} to='/dashboard/schedule/upload'>
                  <Translate id='footer.dashboard.upload' />
                </List.Item>
                <List.Item as={Link} to='/dashboard/tools/messages'>
                  <Translate id='footer.dashboard.messages' />
                </List.Item>
                <List.Item as={Link} to='/dashboard/tools/users'>
                  <Translate id='footer.dashboard.users' />
                </List.Item>
              </List>
            </Grid.Column>
          ) : null}
          <Grid.Column
            width={canSeeDashboard ? '4' : '6'}
            className='no vertical padding'>
            <Header inverted={preferences.isDarkModeEnabled} size='small'>
              <Translate id='footer.support_and_resources.title' />
            </Header>
            <List inverted={preferences.isDarkModeEnabled} link size='medium'>
              <List.Item as={Link} to='/help'>
                <Translate id='footer.support_and_resources.help' />
              </List.Item>
              <List.Item as={Link} to='/privacy'>
                <Translate id='footer.support_and_resources.privacy' />
              </List.Item>
              <List.Item as={Link} to='/terms'>
                <Translate id='footer.support_and_resources.terms' />
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column
            width={canSeeDashboard ? '4' : '5'}
            className='no vertical padding'>
            <Header inverted={preferences.isDarkModeEnabled} size='small'>
              <Translate id='footer.external.title' />
            </Header>
            <List inverted={preferences.isDarkModeEnabled} link size='medium'>
              <List.Item as='a' href='https://olympuscollege.itslearning.com/'>
                <Translate id='footer.external.itslearning' /> &nbsp;
                <Icon name='external' link />
              </List.Item>
              <List.Item as='a' href='https://olympus.magister.net/'>
                <Translate id='footer.external.magister' /> &nbsp;
                <Icon name='external' link />
              </List.Item>
              {config && config.promote_ios_beta ? (
                <List.Item
                  as='a'
                  href='https://testflight.apple.com/join/6QKS4kjQ'>
                  <Translate id='footer.external.ios_app' /> &nbsp;
                  <Label
                    size='tiny'
                    horizontal
                    color={preferences.isDarkModeEnabled ? 'black' : null}>
                    <Translate id='footer.external.beta' />
                  </Label>
                </List.Item>
              ) : null}
              {config && config.promote_android_beta ? (
                <List.Item
                  as='a'
                  href='https://play.google.com/apps/testing/ga.ocrooster.app'>
                  <Translate id='footer.external.android_app' /> &nbsp;
                  <Label
                    size='tiny'
                    horizontal
                    color={preferences.isDarkModeEnabled ? 'black' : null}>
                    <Translate id='footer.external.beta' />
                  </Label>
                </List.Item>
              ) : null}
            </List>
          </Grid.Column>
        </Grid>
        <Divider />
        <List
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
          horizontal
          inverted={preferences.isDarkModeEnabled}
          link
          size='medium'>
          <List.Item style={{ margin: 0 }}>
            © 2018-{new Date().getFullYear()} Antipy V.O.F.
          </List.Item>
          <List.Item style={{ margin: '0 10px' }}>&bull;</List.Item>
          <List.Item style={{ margin: 0 }} as='a' href='https://antipy.com'>
            <Translate id='footer.made_by' /> &nbsp;
            <Icon name='external' link />
          </List.Item>
        </List>
      </Container>
    </Segment>
  );
});
