import 'firebase/remote-config';

import { fire } from './firebase';
import { isDevelopment } from './services';

export interface Config {
  period_timings: string[];
  promote_ios_beta: boolean;
  promote_android_beta: boolean;
}

fire.remoteConfig().defaultConfig = {
  promote_ios_beta:
    '["08:10 - 09:00","09:00 - 09:50","09:50 - 10:40","11:05 - 11:55","11:55 - 12:45","13:10 - 14:00","14:00 - 14:50","14:50 - 15:40","15:40 - 16:30"]',
  promote_android_beta: false,
  period_timings: false,
};

fire.remoteConfig().settings.minimumFetchIntervalMillis = isDevelopment
  ? 1000
  : 1000 * 60 * 5;

export class ConfigService {
  private activated = false;

  async getConfig(): Promise<Config> {
    if (!this.activated) {
      await fire.remoteConfig().fetchAndActivate();
      this.activated = true;
    }

    const {
      period_timings,
      promote_ios_beta,
      promote_android_beta,
    } = fire.remoteConfig().getAll();
    return {
      period_timings: JSON.parse(period_timings.asString()),
      promote_ios_beta: promote_ios_beta.asBoolean(),
      promote_android_beta: promote_android_beta.asBoolean(),
    };
  }
}
