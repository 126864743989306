import React, { useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';

import translationsEN from './en.json';
import translationsNL from './nl.json';

export interface LocalizeProps extends LocalizeContextProps {
  children: React.ReactNode;
}

function Localizations(props: LocalizeProps) {
  const { initialize, addTranslationForLanguage, setActiveLanguage } = props;

  useEffect(() => {
    initialize({
      languages: [
        { name: 'Nederlands', code: 'nl' },
        { name: 'English', code: 'en' },
      ],
      options: { renderToStaticMarkup },
    });

    addTranslationForLanguage(translationsEN, 'en');
    addTranslationForLanguage(translationsNL, 'nl');

    var userLang = navigator.language;
    if (userLang.includes('nl')) {
      setActiveLanguage('nl');
    } else {
      setActiveLanguage('en');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return props.children;
}

// @ts-ignore
export default withLocalize(Localizations);
