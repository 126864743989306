import { RTDBScheduleService } from './schedules';
import { TranslateFunction } from 'react-localize-redux';
import Fuse from 'fuse.js';

export type SearchResult = {
  [key: string]: {
    name: string;
    results: Result[];
  };
};

interface Result {
  id: string;
  title: string;
  description: string;
  category: string;
  searchCategory: string;
}

export class SearchService {
  scheduleService: RTDBScheduleService;
  translate: TranslateFunction;
  searcher: Fuse<Result>;

  constructor(service: RTDBScheduleService, translate: TranslateFunction) {
    this.scheduleService = service;
    this.translate = translate;
  }

  async search(term: string): Promise<SearchResult> {
    if (!this.searcher) {
      const metadata = await this.scheduleService.getMetadata();

      const searchData = metadata.map<Result>((schedule) => {
        const searchCategory =
          schedule.info.category === 'teacher' ||
          schedule.info.category === 'room'
            ? (this.translate(
                `search.categories.${schedule.info.category}`
              ) as string)
            : schedule.info.category;

        return {
          id: schedule.id,
          title: schedule.info.name,
          description: schedule.info.id,
          category:
            schedule.info.category === 'class'
              ? schedule.info.name
              : searchCategory,
          searchCategory,
        };
      });
      this.searcher = new Fuse<Result>(searchData, {
        shouldSort: true,
        threshold: 0.3,
        keys: ['title', 'description', 'searchCategory'],
      });
    }

    const intermediary = this.searcher.search(term);
    const results: SearchResult = {};

    intermediary.forEach((r) => {
      const s = r.item;
      if (!results[s.category]) {
        results[s.category] = {
          name: s.category,
          results: [],
        };
      }
      results[s.category].results.push(s);
    });

    return results;
  }
}
