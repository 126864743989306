export class PreferencesService {
  set isDarkModeEnabled(isDarkModeEnabled: boolean) {
    localStorage.setItem('dark_mode', isDarkModeEnabled.toString());
  }

  get isDarkModeEnabled() {
    return localStorage.getItem('dark_mode') === 'true';
  }

  set scheduleOrientation(orientation: 'horizontal' | 'vertical') {
    localStorage.setItem('schedule_orientation', orientation);
  }

  get scheduleOrientation() {
    return localStorage.getItem('schedule_orientation') as
      | 'horizontal'
      | 'vertical';
  }

  set cacheDisabled(disabled: boolean) {
    localStorage.setItem('cache_disabled', disabled.toString());
  }

  get cacheDisabled() {
    return localStorage.getItem('cache_disabled') === 'true';
  }

  set groupClasses(enabled: boolean) {
    localStorage.setItem('group_classes', enabled.toString());
  }

  get groupClasses() {
    return localStorage.getItem('group_classes') === 'true';
  }
}

export const preferences = new PreferencesService();
